<template>
    <div>
      <b-card no-body>
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <b-col cols="12" md="4">
              <b-form-group label="Name" label-for="name" class="mb-0">
                <b-form-input
                  id="name"
                  v-model="search.name"
                  class="form-control"
                  placeholder="Please Enter Name"
                />
              </b-form-group>
            </b-col>
<!--   
            <b-col cols="12" md="4">
              <b-form-group label="Name english" label-for="name" class="mb-0">
                <b-form-input
                  id="name"
                  v-model="search.name_en"
                  class="form-control"
                  placeholder="Please Enter Name"
                />
              </b-form-group>
            </b-col> -->
            <!-- <b-col cols="12" md="4">
                <b-form-group label="Status" label-for="status" class="mb-0">
                  <v-select
                    v-model="search.status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="statusOptions"
                    :reduce="(val) => val.value"
                    input-id="status"
                  />
                </b-form-group>
              </b-col> -->
            <b-col
              cols="12"
              md="4"
              class="d-flex align-items-end justify-content-end"
            >
              <b-button variant="outline-primary" @click="resetFilter()">
                Reset
              </b-button>
              <b-button variant="primary" class="ml-2" @click="searchFilter()">
                Search
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-card>
      <b-card no-body>
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Search -->
            <b-col cols="12" v-if="$can('create', 'sportSeason')">
              <div class="d-flex align-items-center justify-content-end">
                <b-button variant="primary" @click="showAddSportSeasonModal()">
                  <span class="text-nowrap">
                    <feather-icon icon="PlusIcon" /> New
                  </span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <b-table
          ref="refSportSeasonListTable"
          class="position-relative table-white-space mb-0 max-height-table"
          sticky-header
          head-variant="light"
          :items="sportSeasonLists"
          responsive
          :fields="tableColumns"
          primary-key="index"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(index)="data">
            {{ perPage * (currentPage - 1) + data.index + 1 }}
          </template>
          <template #cell(name)="data">
            <b-link class="cursor-pointer text-primary">
              <!-- @click="showEditSportSeasonModal(data.item)" -->
              {{ data.item.name }}
            </b-link>
          </template>
          <template #cell(name_en)="data">
            <b-link class="cursor-pointer text-primary">
              <!-- @click="showEditSportSeasonModal(data.item)" -->
              {{ data.item.name_en }}
            </b-link>
          </template>
  
          <template #cell(logo)="data">
            <b-link
              :href="baseUrl + data.item.logo"
              target="_blank"
              class="cursor-pointer"
              v-if="data.item.logo != null"
            >
              <b-img :src="baseUrl + data.item.logo" fluid class="mr-2 rounded" />
            </b-link>
            <b-link v-else> </b-link>
          </template>
  
          <template #cell(created_by)="data">
            {{ data.item.created_by }}
          </template>
          <template #cell(updated_by)="data">
            {{ data.item.updated_by }}
          </template>
          <template #cell(created_at)="data">
            {{ data.item.created_at }}
          </template>
  
          <template #cell(updated_at)="data">
            {{ data.item.updated_at }}
          </template>
  
          <template #cell(action)="data">
            <b-link
              v-if="$can('edit', 'sportSeason')"
              class="font-weight-bold text-nowrap"
              @click="showEditSportSeasonModal(data.item)"
            >
              Edit
            </b-link>
          </template>
        </b-table>
        <div class="p-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              "
            >
              <span class="text-muted"
                >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                {{ dataMeta.of }} entries</span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-end
              "
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalSportSeason"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
  
      <!-- Modal: Add sports Group -->
      <!-- <add-sports-group-modal
                @refetch-data="refetchData"
            /> -->
  
      <!-- Modal: Edit sports Group -->
      <!-- <edit-sports-group-modal
                :sports-group-detail.sync="sportSeasonDetail"
                @refetch-data="refetchData"
            /> -->
    </div>
  </template>
  
  <script>
  import {
    BButton,
    BModal,
    VBModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BTr,
    BTh,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BImg,
  } from "bootstrap-vue";
  import Ripple from "vue-ripple-directive";
  import vSelect from "vue-select";
  import { ref, watch, computed, onUnmounted } from "@vue/composition-api";
  import { formatDateTime } from "@core/utils/filter";
  import { useToast } from "vue-toastification/composition";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import store from "@/store";
  import baseUrl from "@/libs/base-url";
  import sportsStoreModule from "@/views/sports/sportsStoreModule";
  // import AddsportSeasonModal from '@/views/sports/AddsportSeasonModal.vue'
  // import EditsportSeasonModal from '@/views/sports/EditsportSeasonModal.vue'
  
  export default {
    components: {
      // AddsportSeasonModal,
      // EditsportSeasonModal,
      BButton,
      BFormGroup,
      BModal,
      BCard,
      BRow,
      BCol,
      BFormInput,
      BTable,
      BTr,
      BTh,
      BMedia,
      BAvatar,
      BLink,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
      vSelect,
      BImg,
    },
    directives: {
      "b-modal": VBModal,
      Ripple,
    },
    data() {
      return {
        sportSeasonDetail: null,
      };
    },
    methods: {
      showAddSportSeasonModal() {
        this.$bvModal.show("modal-add-sports-season");
      },
      showEditSportSeasonModal(data) {
        this.sportSeasonDetail = data;
        this.$bvModal.show("modal-edit-sports-season");
      },
    },
    setup() {
      if (!store.hasModule("sports"))
        store.registerModule("sports", sportsStoreModule);
  
      const toast = useToast();
  
      const search = ref({
        name: "",
        // name_en: "",
      });
  
      const nameEnFilter = ref(null);
      const nameFilter = ref(null);
  
      const perPage = ref(25);
      const totalSportSeason = ref(0);
      const currentPage = ref(1);
      const perPageOptions = [10, 25, 50, 100];
      const sortBy = ref("id");
      const isSortDirDesc = ref(true);
  
      const refSportSeasonListTable = ref(null);
  
      const tableColumns = [
        { key: "index", label: "#" },
        "name",
        // "name_en",
  
        // "logo",
        "created_by",
        "updated_by",
        "created_at",
        "updated_at",
        // 'action',
      ];
  
      const refetchData = () => {
        refSportSeasonListTable.value.refresh();
      };
  
      watch([currentPage, nameFilter], () => {
        refetchData();
      });
  
      const sportSeasonLists = (ctx, callback) => {
        store
          .dispatch("sports/fetchSportSeason", {
            name: nameFilter.value,
            // name_en: nameEnFilter.value,
            page: currentPage.value,
          })
          .then((response) => {
            // console.log(12, response);
            //   const { sportSeasons, total, pageSize } = response;
            callback(response.sportSeason);
            totalSportSeason.value = response.total;
            perPage.value = response.pageSize;
          });
      };
  
      const dataMeta = computed(() => {
        const localItemsCount = refSportSeasonListTable.value
          ? refSportSeasonListTable.value.localItems.length
          : 0;
        return {
          from:
            perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
          to: perPage.value * (currentPage.value - 1) + localItemsCount,
          of: totalSportSeason.value,
        };
      });
  
      const statusOptions = [
        { label: "Active", value: 1 },
        { label: "inActive", value: 2 },
      ];
  
      const resolveStatus = (status) => {
        if (status == 1) return { label: "Active", variant: "primary" };
        if (status == 2) return { label: "inActive", variant: "danger" };
        return { label: "Pending", variant: "secondary" };
      };
  
      const resetFilter = () => {
        search.value.name = "";
        search.value.status = "";
        nameFilter.value = null;
        nameEnFilter.value = null;
      };
  
      const searchFilter = () => {
        nameFilter.value = search.value.name;
        nameEnFilter.value = search.value.status;
      };
  
      return {
        tableColumns,
        perPage,
        currentPage,
        totalSportSeason,
        dataMeta,
        perPageOptions,
        sortBy,
        isSortDirDesc,
        refSportSeasonListTable,
  
        sportSeasonLists,
        refetchData,
        nameFilter,
        search,
  
        resetFilter,
        nameEnFilter,
  
        resolveStatus,
        statusOptions,
  
        formatDateTime,
        searchFilter,
        baseUrl,
      };
    },
  };
  </script>
  